<template>
  <div class="container">
    <div class="header">
      <van-icon
          class="header-return"
          name="arrow-left"
          size="25"
          color="#bcbec0"
          @click="onClickHeaderReturn()"
      />
      Store List
    </div>
    <div class="content">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="No more data"
            loading-text="Loading more..."
            :error.sync="error"
            error-text="Load fail! Click reload"
            @load="onLoad"
        >

          <div style="padding:10px;" v-for="(item,index) in storeList" :key="index">
            <Store :store="item" @onDelete="onDelete(item,index)" @onEdit="onEdit(item,index)"/>
          </div>

        </van-list>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import Store from "@/components/Store.vue";
import storeApi from "@/api/store";
import {Dialog} from "vant";

export default {
  name: "StoreList",
  components: {Store},
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      error: false,
      storeList: [],
      pageNum: 1,
      pageSize: 10
    };
  },
  created() {
  },
  async mounted() {
    this.onLoad()
  },
  computed: {},
  methods: {
    onClickHeaderReturn() {
      this.$router.back()
    },
    async onRefresh() {
      this.refreshing = true;
      this.onLoad();
    },
    async onLoad() {
      //如果是刷新
      if (this.refreshing) {
        this.pageNum = 1;
        this.storeList = [];
      }
      try {
        const resp = await storeApi.storeList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        });

        this.storeList = resp.data
        const total = resp.total
        //刷新结束
        if (this.refreshing) {
          this.refreshing = false;
        } else {
          this.loading = false;
        }
        if (this.storeList.length >= total) {
          this.finished = true;
        }
        this.pageNum += 1;
      } catch (e) {
        console.log(e)
        this.loading = false;
        this.error = true;
      }
    },
    onDelete(item, index) {
      Dialog.confirm({
        title: 'Delete store address',
        message: ''
      }).then(() => {
        storeApi.deleteStore(item.id)
        this.storeList.splice(index, 1)
      }).catch(() => {
      })
    },
    onEdit(item, index) {
      this.$router.push({
        path: `/storeEdit`,
        query: {
          store: JSON.stringify(item)
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    z-index:1;
    height: 60px;
    position: fixed;
    top:0;
    background: #f6f6f6;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #545554;

    .header-return {
      position: absolute;
      left: 10px;
    }
  }

  .content {
    margin-top:60px;
    height: 100%;
    overflow-y: auto;

    // .history-item:last-child {
    //   margin-bottom: 16px;
    // }

    .history-item {
      margin-bottom: 10px;
      background: #ffffff;

      .history-header {
        display: flex;
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #eeeeee;

        .header-title {
          font-size: 16px;
          font-weight: bold;
          color: #555555;
        }

        .header-time {
          font-size: 14px;
          color: #999999;
          margin-left: auto;
        }
      }

      .history-content {
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px 16px;

        .history-row {
          display: flex;
          margin-top: 16px;

          .history-left-title {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }

          .history-right-value {
            max-width: 55%;
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            margin-left: auto;
            text-align: right;
          }
        }

        .history-column {
          display: flex;
          flex-direction: column;
          margin-top: 16px;

          .history-left-title {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }

        .history-button-apply {
          height: 30px;
          margin: 16px 0 16px auto;
          background: #3fa7bc;
          color: white;
          border: none;
          border-radius: 8px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
