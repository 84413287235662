<template>
  <div class="store-item-wrap">
    <div class="title">{{ store.name }}</div>
    <div class="content">{{ store.street }}</div>
    <div class="content">{{ store.email }}</div>
    <div class="content">{{ store.phone }}</div>
    <div class="divider"></div>
    <div class="edit-wrap">
      <div style="display: flex;align-items: center">
        <van-image :src="require('@/assets/logo/icon-store-views.png')" width="20px" height="20px"/>
        <span style="margin-left:8px">{{ store.viewCount }}</span>
      </div>

      <div class="btn-wrap">
        <div class="btn" @click="onEdit">Edit</div>
        <div class="btn" @click="onDelete">Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Store",
  props: {
    store: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onEdit() {
      this.$emit('onEdit')
    },
    onDelete() {
      this.$emit('onDelete')
    }
  }
}
</script>


<style scoped lang="less">
.store-item-wrap {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;

  .title {
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }

  .content {
    font-size: 14px;
    padding: 0 10px;
    margin-top: 4px;
    color: #666666;

  }

  .divider {
    margin:10px 0px;
    width: 100%;
    height: 1px;
    background: #EEEEEE;
  }

  .edit-wrap {
    padding: 10px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon-views {
      width: 20px;
      height: 20px;
    }

    .btn-wrap {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .btn {
      width: 50px;
      text-align: center;
      margin-left: 10px;
      border-radius: 8px;
      border: 1px solid #EEEEEE;
      padding: 4px 8px;
    }
  }
}
</style>